import React, { useState } from 'react';
import { FaTimes } from "react-icons/fa";

import axios from 'axios';
const CurrencyApp = ({ isAppOpen, setIsAppOpen }) => {
  const [amount, setAmount] = useState('');
  const [targetCurrency, setTargetCurrency] = useState('USD');
  const [convertedAmount, setConvertedAmount] = useState(null);
  const [error, setError] = useState('');

  const apiKey = 'e03b54151fd8ec28796bde26';  // Replace with your API key
  const baseCurrency = 'CAD';  // Canadian Dollar as base currency

  const fetchExchangeRate = async () => {
    try {
      const response = await axios.get(`https://v6.exchangerate-api.com/v6/${apiKey}/latest/${baseCurrency}`);
      console.log(response.data);  // Log the entire response for debugging

      if (response.data.result === 'error') {
        setError('API Error: ' + response.data.error.type);
        setConvertedAmount(null);
        return;
      }

      if (!response.data.conversion_rates) {
        setError('No conversion rates data available.');
        setConvertedAmount(null);
        return;
      }

      const rate = response.data.conversion_rates[targetCurrency]; 
      if (!rate) {
        setError('Invalid target currency');
        setConvertedAmount(null);
        return;
      }

      const result = (amount * rate).toFixed(2);
      setConvertedAmount(result);
      setError('');
    } catch (err) {
      console.error(err);  // Log the error to the console
      setError('Error fetching data. Please try again later.');
      setConvertedAmount(null);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6">
          <div className="card p-4 shadow-lg">
             <button 
                    onClick={() => setIsAppOpen(false)} 
                    className="absolute top-2 right-2 text-gray-500">
                    <FaTimes size={20} />
                  </button>
            <h1 className="text-center mb-4">Currency Converter</h1>

            <div className="mb-3">
              <label htmlFor="amount" className="form-label">Amount in CAD:</label>
              <input 
                type="number" 
                id="amount" 
                className="form-control"
                value={amount} 
                onChange={e => setAmount(e.target.value)} 
                placeholder="Enter amount"
              />
            </div>

            <div className="mb-3">
              <label htmlFor="targetCurrency" className="form-label">Target Currency:</label>
              <select 
                id="targetCurrency" 
                className="form-select"
                value={targetCurrency} 
                onChange={e => setTargetCurrency(e.target.value)}
              >
                <option value="USD">USD</option>
                <option value="EUR">EUR</option>
                <option value="GBP">GBP</option>
                <option value="AUD">AUD</option>
                <option value="JPY">JPY</option>
                <option value="MXN">MXN</option>
                {/* Add more currencies as needed */}
              </select>
            </div>

            <button onClick={fetchExchangeRate} className="btn btn-primary w-100 mb-3">
              Convert
            </button>

            {convertedAmount && (
              <div className="alert alert-success">
                <h3>Converted Amount:</h3>
                <p>{`${amount} CAD = ${convertedAmount} ${targetCurrency}`}</p>
              </div>
            )}

            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CurrencyApp;
import React from "react";
import { Link } from "react-router-dom";

const HomePage = () => (
    <main className="flex-grow bg-gray-100 text-gray-800 py-10">
             <div className="container mx-auto text-center">
               <h2 className="text-4xl font-bold mb-6">
                Welcome!
               </h2>
               <p className="text-lg mb-10">
                A place to explore our solutions, test web application demos, and more!
               </p>
               <div className="flex justify-center gap-6">
                 <a
                  href="https://yellowray.solutions"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-3 bg-blue-500 text-white rounded-lg shadow-lg hover:bg-blue-600 transition"
                >
                  Visit Solutions Site
                </a>
                <a
                  href="https://www.yrcgi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="px-6 py-3 bg-green-500 text-white rounded-lg shadow-lg hover:bg-green-600 transition"
                >
                  Visit Main Website
                </a>
              </div>
            </div>
          </main>
);

export default HomePage;
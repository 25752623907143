import { useState } from "react";
import { FaTimes } from "react-icons/fa";

export default function SalaryCalculator({ isAppOpen, setIsAppOpen }) {
  const [salary, setSalary] = useState(0);
  const [period, setPeriod] = useState("yearly");
  const [hoursPerWeek, setHoursPerWeek] = useState("");
  const [biweeklyWork, setBiweeklyWork] = useState(false);
  

  const formatNumber = (num) => {
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  const effectiveHoursPerWeek = biweeklyWork ? (Number(hoursPerWeek) / 2) : Number(hoursPerWeek);
  const annualWorkHours = (effectiveHoursPerWeek || 40) * 52; // Default to 40 hours per week if empty

  const periods = {
    yearly: 1,
    monthly: 12,
    biweekly: 26,
    weekly: 52,
    daily: annualWorkHours / 260, // Assuming 260 workdays per year
    hourly: annualWorkHours, // Total annual work hours
  };

  const calculateSalaries = (value, unit, hoursPerWeek, worksEverySecondWeek) => {
    if (!value || !hoursPerWeek) return {};

    // const yearlySalary = value * periods[unit];
    // const yearlySalary = unit === "daily" ? value * (worksEverySecondWeek ? 130 : 260) : value * periods[unit];
    const yearlySalary = unit === "daily" 
    ? value * (worksEverySecondWeek ? 130 : 260) 
    : unit === "hourly"
    ? value * hoursPerWeek * (worksEverySecondWeek ? 26 : 52) 
    : value * periods[unit];

    // Adjust annual work hours for biweekly workers
    const adjustedAnnualWorkHours = worksEverySecondWeek ? (hoursPerWeek * 26) : (hoursPerWeek * 52);
    
    // Effective workdays per year (assuming a 5-day workweek)
    //const hoursPerDay = hoursPerWeek / 5;
    //const workdaysPerYear = adjustedAnnualWorkHours / hoursPerDay;

    return {
      yearly: formatNumber(yearlySalary),
      monthly: formatNumber(yearlySalary / 12),
      biweekly: formatNumber(yearlySalary / 26),
      weekly: formatNumber(yearlySalary / 52),
      // first try -daily: formatNumber(yearlySalary / workdaysPerYear),
      // second try-daily: formatNumber(yearlySalary / (biweeklyWork ? 130 : 260)),
      // third try- almost had it-daily: formatNumber(value),
      daily: formatNumber(unit === "hourly" ? value * (hoursPerWeek / 5) : value),
      hourly: formatNumber(yearlySalary / adjustedAnnualWorkHours),
    };
  };

  const salaries = calculateSalaries(Number(salary), period, Number(hoursPerWeek), biweeklyWork);

  

  return (
    <div className="relative flex flex-col items-center p-6 max-w-md mx-auto bg-white shadow-lg rounded-2xl">
      <button 
        onClick={() => setIsAppOpen(false)} 
        className="absolute top-2 right-2 text-gray-500">
        <FaTimes size={20} />
      </button>
      <h2 className="text-2xl font-bold mb-4">Salary Calculator</h2>
      
      <input
        type="text" // Change from "number" to "text"
        placeholder="Enter Amount"
        value={salary ? `$${salary}` : ""} // Show "$" before the value
        onChange={(e) => {
          const rawValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove everything except numbers & "."
        setSalary(rawValue); // Store only numeric value
  }}
  className="w-full p-2 border rounded-md mb-4"
/>

      <select
        value={period}
        onChange={(e) => setPeriod(e.target.value)}
        className="w-full p-2 border rounded-md mb-4"
      >
        {Object.keys(periods).map((key) => (
          <option key={key} value={key}>
            {key.charAt(0).toUpperCase() + key.slice(1)}
          </option>
        ))}
      </select>

      <input
        type="number"
        placeholder="Hours per week"
        value={hoursPerWeek}
        onChange={(e) => setHoursPerWeek(e.target.value)}
        className="w-full p-2 border rounded-md mb-4"
      />

      {/* Checkbox for "Every Second Week" */}
      <label className="flex items-center space-x-2 mb-4">
        <input
          type="checkbox"
          checked={biweeklyWork}
          onChange={() => setBiweeklyWork(!biweeklyWork)}
          className="w-4 h-4"
        />
        <span>Works every second week</span>
      </label>

      {/* Salary Breakdown */}
      <div className="w-full text-left">
        {Object.keys(salaries).length > 0 ? (
          Object.entries(salaries).map(([key, value]) => (
            <div key={key} className="flex justify-between py-2 border-b">
              <span className="capitalize font-medium">{key}:</span>
              <span>${value}</span>
            </div>
          ))
        ) : (
          <p className="text-gray-500 text-center">Enter salary and hours per week to calculate</p>
        )}
      </div>
    </div>
  );
}
